<template>
    <div class="">
        <div class="actions__container">
            <div>
                <router-link class="btn btn-primary w-40 inline-block mr-1 mb-2" :to="{ name: 'city-arrivals-create' }">
                    Создать
                </router-link>
            </div>
        </div>

        <ContentSearch />

        <ContentTable
            :table-actions="tableActions"
            :headers="tableHeaders"
            :table-rows="tableData"
            :meta="pagination"
            :loading="loading"
            @edit="editAction"
            @delete="deleteAction"
        />

        <PopupDeleteModal
            :is-open="modalDeleteOpen"
            :entity-name="modalDeleteCaption"
            :modal-data="modalDeleteRow"
            @update:isOpen="modalDeleteOpen = $event"
            @deleteModalAccepted="onDeleteModalAccepted"
        />
    </div>
</template>

<script>
import ContentTable from '@/components/tables/ContentTable';
import PopupDeleteModal from '@/components/modals/PopupDeleteModal';
import ContentSearch from '@/components/base/ContentSearch';
import { mapGetters } from 'vuex';
import { pageMixin } from '@/mixins/pageMixin';

export default {
    name: 'Main',
    components: { ContentSearch, PopupDeleteModal, ContentTable },
    mixins: [pageMixin],
    async beforeRouteUpdate(to, from, next) {
        await this.fetchData({ ...to.query });
        next();
    },
    data() {
        return {
            tableActions: [
                { name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                { name: 'delete', title: 'удалить', class: 'bg-red-400', icon: 'Trash2Icon', cbName: 'delete' },
            ],
            addButtonDelete: false,
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'title', label: 'Название' },
                { field: 'slug', label: 'Ссылка' },
                { field: 'parent_name', label: 'Родитель' },
            ],
            loading: false,
            tableData: [],
            pagination: null,
            modalDeleteOpen: false,
            modalDeleteCaption: null,
            modalDeleteRow: null,
        };
    },
    computed: {
        ...mapGetters({ getUser: 'user/getUser' }),
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Города прибытия');
        await this.fetchData(this.$route.query);
    },
    methods: {
        async fetchData(params) {
            try {
                this.loading = true;
                const { data } = await this.axios.get('/city-arrivals', { params });
                this.tableData = data.data;
                this.pagination = data.meta;

                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch data: ' + ex);
            }
        },

        editAction(row) {
            this._pMTransitionToEditPageWithQuery('city-arrivals-edit', { id: row.id });
        },
        deleteAction(row) {
            this.modalDeleteOpen = true;
            this.modalDeleteCaption = 'направление';
            this.modalDeleteRow = row;
        },
        onDeleteModalAccepted(data) {
            this.axios
                .delete('/city-arrivals/' + data.id)
                .then(() => {
                    this.fetchData(this.$route.query);
                    this.modalDeleteOpen = false;
                })
                .catch((ex) => {
                    this.$notify({
                        title: 'Ошибка',
                        text: ex.response.data.message,
                        type: 'error',
                    });
                });
        },
    },
};
</script>

<style scoped></style>
